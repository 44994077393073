import { getAccessToken } from '@neotech-solutions-org/neofusion-fe-shared';
import { useCallback, useEffect, useState } from 'react';
import { postData } from '../utils/api';

const EVENT_MESSAGES = {
  NAVIGATE_TO_GAME: 'NAVIGATE_TO_GAME',
};

export const useIFrameData = () => {
  const [url, setURL] = useState<string>('');

  const handleMessage = useCallback((event: MessageEvent) => {
    if (event.data?.type === EVENT_MESSAGES.NAVIGATE_TO_GAME) {
      window.location.href = event.data.url;
    }
  }, []);

  useEffect(() => {
    window.addEventListener('message', handleMessage);
    return () => {
      window.removeEventListener('message', handleMessage);
    };
  }, [handleMessage]);

  useEffect(() => {
    const fetchURL = async () => {
      try {
        const token = await getAccessToken();
        const response = await postData('/marketplace/generateUrl', { token }, 'casino');
        setURL(response?.url);
      } catch (error) {
        console.error(error);
      }
    };

    fetchURL();
  }, [setURL]);

  return {
    url,
  };
};
