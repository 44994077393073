import { useAuth } from '@neotech-solutions-org/neofusion-fe-shared';
import { useCallback, useEffect, useState } from 'react';
import { JackpotPool, JackpotWonMessage } from '../@types';
import useWebsocket from './useWebsocket';

const useWinningJackpot = () => {
  const [winningJackpot, setWinningJackpot] = useState<JackpotPool | null>(null);

  const { userId } = useAuth();

  const jackpotUpdater = (data: JackpotWonMessage[]) => {
    data.forEach((message) => {
      const messageData = message[userId];

      if (!messageData) {
        return;
      }

      if (messageData.event === 'jackpotWinner') {
        setWinningJackpot(messageData.payload);
      }
    });
  };

  const { joinRoom: joinJackpotRoom } = useWebsocket({
    callback: jackpotUpdater,
  });

  const clearJackpot = useCallback(() => {
    setWinningJackpot(null);
  }, []);

  useEffect(() => {
    if (userId) {
      joinJackpotRoom(userId);
    }
  }, [joinJackpotRoom, userId]);

  return {
    clearJackpot,
    winningJackpot,
  };
};

export default useWinningJackpot;
