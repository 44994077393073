import { Stack } from '@mui/material';
import Heading from '../atoms/Heading';
import EventsTable from '../organisms/tables/EventsTable';
import { QUERY_KEYS } from '../../constants';
import { usePublishedEvents } from '../../queries';
import useLocalization from '../../hooks/useLocalization';

type Props = {
  sportId?: string;
  sportName: string;
};

const UpcomingEventsTable = ({ sportId, sportName }: Props) => {
  const { t } = useLocalization();

  const { data: upcomingEventsData } = usePublishedEvents({
    queryKey: [QUERY_KEYS.upcomingMatches],
    queryParams: {
      limit: 10,
      sportId,
      sort: 'startDate',
    },
    options: {
      enabled: !!sportId,
    },
  });

  return (
    <>
      <Heading spacing={0.5} direction='column' alignItems='start' to={`${sportId}/upcoming-matches`}>
        <Heading.Subtitle>{sportName}</Heading.Subtitle>
        <Stack direction='row' alignItems='center' justifyContent='space-between' width={1}>
          <Heading.Title>{t('upcomingMatches')}</Heading.Title>
          <Heading.Link />
        </Stack>
      </Heading>
      {!!upcomingEventsData?.items && sportId && (
        <EventsTable data={upcomingEventsData?.items} showTableHead topMarketType={upcomingEventsData?.topMarketType} />
      )}
    </>
  );
};

export default UpcomingEventsTable;
