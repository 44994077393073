import { Box, Typography, keyframes } from '@mui/material';
import dayjs from 'dayjs';
import { useEffect, useMemo } from 'react';
import { StyleObj } from '../../@types';
import { PUNTER_COUNTDOWN_DURATION, QUERY_KEYS } from '../../constants';
import { useBetslip } from '../../contexts/BetslipContext';
import useCountdownTimer from '../../hooks/useCountdownTimer';
import { useInvalidateQuery } from '../../hooks/useInvalidateQuery';

const blink = keyframes`
  from {
    opacity: 1;
  }
  to {
    opacity: 0.25;
  }
`;

const makeStyles = (timeExpiring: boolean): StyleObj => ({
  timerWrapper: {
    display: 'flex',
    alignItems: 'center',
    gap: 1,
  },
  timeRemaining: {
    fontWeight: 700,
    fontSize: 16,
    color: timeExpiring ? 'error.main' : 'inherit',
    animation: timeExpiring ? `${blink} 1s linear infinite` : 'none',
  },
});

type Props = {
  ticketId: string;
  createdAt: string;
};

const calculateRemainingSeconds = (createdAt: string, duration: number) => {
  const createdAtTimestamp = dayjs(createdAt);
  const currentTimestamp = dayjs();
  return Math.max(createdAtTimestamp.add(duration, 'second').diff(currentTimestamp, 'second'), 0);
};

const ReofferedBetslipCountdown = ({ ticketId, createdAt }: Props) => {
  const remainingSeconds = useMemo(() => calculateRemainingSeconds(createdAt, PUNTER_COUNTDOWN_DURATION), [createdAt]);

  const { seconds: operatorTimeRemaining, resetTimer } = useCountdownTimer(remainingSeconds);

  const { removeReofferedBettingSlip } = useBetslip();

  const invalidateData = useInvalidateQuery();

  const timeExpiring = operatorTimeRemaining < 10;

  useEffect(() => {
    if (operatorTimeRemaining === 0) {
      removeReofferedBettingSlip(ticketId);
      resetTimer();
      invalidateData([QUERY_KEYS.pendingBetslipsCount]);
    }
  }, [operatorTimeRemaining, removeReofferedBettingSlip, ticketId, resetTimer, invalidateData]);

  useEffect(() => {
    resetTimer();
  }, [resetTimer, ticketId]);

  const styles = makeStyles(timeExpiring);

  return (
    <Box sx={styles.timerWrapper}>
      <Typography sx={styles.timeRemaining}>{operatorTimeRemaining}</Typography>
    </Box>
  );
};

export default ReofferedBetslipCountdown;
